<template>
  <div>
    <titleNav title="社保小助手" :left-arrow="true" left-text="" />
    <div class="text" style="font-size:16px">
      一、甲方委托乙方代其缴纳一下社会保险：
      <div>1,灵活就业养老保险</div>
      2,灵活就业医疗保险
      <div>社会保险的缴费数额参照石家庄市的标准执行</div>
      二、收费标准及缴费时间：
      <div>
        1,乙方在为甲方提供灵活就业人员养老保险、医疗保险或灵活就业人员养老保险服务期间,甲方应支付乙方服务费240元/年。
      </div>
      <div>
        2,服务费按年收取,从开户申请日期起,预交一年,中途中断概不退款,服务费到期后预收取下年服务费。甲方应于每年1月5日前缴纳当年服务费。
      </div>
      <div>
        3,甲方未办理相关中断手续的,协议条款继续执行,转出时需要提前20个工作日在线申请。
      </div>
      <div>
        4,因甲方原因未及时办理中断手续的,由此产生的服务费用,全权由甲方负责。
      </div>
      <div>
        5,退休前3个月提前联系办理退休事宜,逾期造成的后果本人自行承担一切责任。
      </div>
      三、甲方的责任和权利：
      <div>
        1,甲方应秉承诚信原则向本网站提供注册/代办所需信息和材料,甲方确认其提供的信息、材料真实、准确、完整、合法有效。如因甲方原因未按乙方要求及时、完整提交信息、资料的,视为甲方放弃委托。如甲方信息有任何变更,应立即通知乙方,负责甲方须承担因个人信息错误导致乙方无法及时、正常履行各项服务的全部责任,并自行承担待遇受影响的全部责任。
      </div>
      <div>
        2,甲方有权要求乙方按照本协议约定提供灵活就业人员养老保险、医疗保险或灵活就业人员养老保险代办服务。同时应向乙方及时足额支付委托服务费。
      </div>
      <div>
        3,甲方应根据乙方要求及时将当年的委托服务费一次性足额支付服务费,如经乙方催告甲方仍不知福费用的,乙方有权为甲方办理灵活就业人员养老保险、医疗保险或灵活就业人员养老保险停保手续,由此产生的的一切后果由甲方自行承担。
      </div>
      四、乙方的责任和权利：
      <div>
        1,乙方可根据甲方的实际需要,按照国家及石家庄市社会保险相关政策规定为甲方办理灵活就业人员养老保险、医疗保险或灵活就业人员养老保险的代办,参保等服务。
      </div>
      <div>
        2,乙方可为甲方提供灵活就业人员养老保险、医疗保险或灵活就业人员养老保险的服务,但甲方的参保条件、待遇享受条件、待遇享受标准等,均以石家庄市政策确定为准。
      </div>
      <div>
        3,乙方所提供的服务以政府部门发布的石家庄市灵活就业人员养老保险、医疗保险政策规定为准。如因政策变更,乙方相关服务也会随政策变动而相应调整。
      </div>
      <div>
        4,在甲方未按约定支付足额委托服务费之前,乙方有权不向甲方提供服务或者终止服务。
      </div>
      <div>
        5,乙方应尊重并保护用户的个人隐私信息,为了给用户提供更准确、及时的服务,乙方将按本约定使用用户个人信息。
      </div>
      五、违约责任
      <div>
        甲方须在本协议规定的时间内向乙方足额支付委托服务费。若甲方逾期支付委托服务费,乙方有权向甲方追缴委托服务费和滞纳金,滞纳金按每日逾期未支付服务费的千分之五收取。若因甲方原因造成相关业务不能正常办理、相关待遇不能正常享受,或甲方被相关行政管理部门征收滞纳金的,由甲方自行承担责任。
      </div>
      六、终止和解除
      <div>1,本协议自甲方向乙方付费之日起生效。</div>
      <div>
        2,如甲乙双方有一方提出终止,则本协议自实际服务终止之日自行解除。
      </div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
export default {
  components: {
    titleNav,
  }
}
</script>